<template>
    <v-expand-transition>
        <div class="text-center pa-4">
            <v-btn
                :prepend-icon="
                    !getPrivacyPolicyDialogOpen
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                "
                variant="tonal"
                class="mr-4"
                @click="togglePrivacyPolicyDialogOpen()">
                {{ $t("landingPage.privacyPolicy.button") }}
            </v-btn>
            <v-dialog
                v-model="dialog"
                max-width="600"
                class="position-fixed bottom-0 left-0">
                <v-card
                    max-width="800"
                    max-height="600"
                    min-width="200"
                    prepend-icon="mdi-notebook-outline"
                    :title="$t('landingPage.privacyPolicy.title')">
                    <template v-slot:append>
                        <v-btn
                            icon="mdi-close"
                            @click="togglePrivacyPolicyDialogOpen()"></v-btn>
                    </template>
                    <template v-slot:text>
                        <p>{{ $t("landingPage.privacyPolicy.p1") }}</p>
                        <p>{{ $t("landingPage.privacyPolicy.p2") }}</p>
                        <ol>
                            <li>
                                {{ $t("landingPage.privacyPolicy.list1.li1") }}
                            </li>
                            <li>
                                {{ $t("landingPage.privacyPolicy.list1.li2") }}
                            </li>
                            <li>
                                {{
                                    $t("landingPage.privacyPolicy.list1.li3.p1")
                                }}
                                <ul>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li1"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li2"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li3"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li4"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li5"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li6"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li7"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li8"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li9"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li10"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list1.li3.li11"
                                            )
                                        }}
                                    </li>
                                </ul>
                            </li>
                            <li>{{ $t("landingPage.privacyPolicy.p3") }}</li>
                            <li>
                                {{ $t("landingPage.privacyPolicy.p4") }}
                                <ul>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list2.li1"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list2.li2"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list2.li3"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list2.li4"
                                            )
                                        }}
                                    </li>
                                </ul>
                            </li>
                            <li>
                                {{ $t("landingPage.privacyPolicy.list3.li4") }}
                            </li>
                            <li>
                                {{
                                    $t("landingPage.privacyPolicy.list3.li5.p1")
                                }}
                                <ul>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list3.li5.li1"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list3.li5.li2"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list3.li5.li3"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list3.li5.li4"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        {{
                                            $t(
                                                "landingPage.privacyPolicy.list3.li5.li5"
                                            )
                                        }}
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </template>
                </v-card>
            </v-dialog>
        </div>
    </v-expand-transition>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

// Components
export default defineComponent({
    name: "BottomBarPrivacyPolicyDialog",

    components: {},
    methods: {
        ...mapActions("LandingPage", [
            "togglePrivacyPolicyDialogOpen",
            "setPrivacyPolicyDialogOpen",
        ]),
    },
    computed: {
        ...mapGetters("LandingPage", {
            getPrivacyPolicyDialogOpen: "getPrivacyPolicyDialogOpen",
        }),
        dialog: {
            get() {
                return this.getPrivacyPolicyDialogOpen;
            },
            set(value) {
                this.setPrivacyPolicyDialogOpen(value);
            },
        },
    },
});
</script>
<style scoped>
p {
    margin-bottom: 16px;
}
ul {
    margin-bottom: 8px;
    margin-left: 8px;
}
ol {
    margin-bottom: 8px;
    margin-left: 8px;
}
li {
    margin-bottom: 8px;
    margin-left: 8px;
}
</style>
