<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4" class="mr-md-0 mr-sm-4">
                <v-img src="../assets/TheHouseTabImg.jpg"></v-img>
            </v-col>
            <v-col
                cols="12"
                md="8"
                class="border-opacity-100 border-green"
                :class="{
                    'border-s-md': $vuetify.display.mdAndUp,
                    'border-t-md': $vuetify.display.smAndDown,
                }">
                <div class="text-body-1 text-md-h5">
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.house.p1") }}
                    </p>
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.house.p2") }}
                    </p>
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.house.p3") }}
                    </p>
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.house.p4") }}
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "Section2TabTheHouse",

    components: {},
});
</script>
<style scoped></style>
