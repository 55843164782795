const state = () => ({
    contactDialogOpen: false,
    topContactDialogOpen: false,
    privacyPolicyDialogOpen: false,
});

const getters = {
    getContactDialogOpen(state) {
        return state.contactDialogOpen;
    },
    getTopContactDialogOpen(state) {
        return state.topContactDialogOpen;
    },
    getPrivacyPolicyDialogOpen(state) {
        return state.privacyPolicyDialogOpen;
    },
};

// actions
const actions = {
    setContactDialogOpen({ commit }, contactDialogOpen) {
        commit("setContactDialogOpen", contactDialogOpen);
    },
    toggleContactDialogOpen({ commit }) {
        commit("toggleContactDialogOpen");
    },
    setTopContactDialogOpen({ commit }, topContactDialogOpen) {
        commit("setTopContactDialogOpen", topContactDialogOpen);
    },
    toggleTopContactDialogOpen({ commit }) {
        commit("toggleTopContactDialogOpen");
    },
    setPrivacyPolicyDialogOpen({ commit }, privacyPolicyDialogOpen) {
        commit("setPrivacyPolicyDialogOpen", privacyPolicyDialogOpen);
    },
    togglePrivacyPolicyDialogOpen({ commit }) {
        commit("togglePrivacyPolicyDialogOpen");
    },
};

// mutations
const mutations = {
    setContactDialogOpen(state, contactDialogOpen) {
        state.contactDialogOpen = contactDialogOpen;
    },
    toggleContactDialogOpen(state) {
        state.contactDialogOpen = !state.contactDialogOpen;
    },
    setTopContactDialogOpen(state, topContactDialogOpen) {
        state.topContactDialogOpen = topContactDialogOpen;
    },
    toggleTopContactDialogOpen(state) {
        state.topContactDialogOpen = !state.topContactDialogOpen;
    },
    setPrivacyPolicyDialogOpen(state, privacyPolicyDialogOpen) {
        state.privacyPolicyDialogOpen = privacyPolicyDialogOpen;
    },
    togglePrivacyPolicyDialogOpen(state) {
        state.privacyPolicyDialogOpen = !state.privacyPolicyDialogOpen;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
