<template>
    <div class="bg-white">
        <section-1-component></section-1-component>
        <section-1-small-screen-card
            v-if="$vuetify.display.smAndDown"></section-1-small-screen-card>
        <section-2-component></section-2-component>
        <section3-component></section3-component>
        <bottom-bar-component></bottom-bar-component>
        <bottom-bar-contact-dialog></bottom-bar-contact-dialog>
        <scroll-to-top-button></scroll-to-top-button>
        <top-bar-contact-dialog></top-bar-contact-dialog>
        <top-bar-contact-dialog-button></top-bar-contact-dialog-button>
        <cookies-sheet></cookies-sheet>
    </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import Section1Component from "../components/Section1Component.vue";
import Section2Component from "../components/Section2Component.vue";
import Section3Component from "../components/Section3Component.vue";
import BottomBarComponent from "../components/BottomBarComponent.vue";
import BottomBarContactDialog from "../components/BottomBarContactDialog.vue";
import ScrollToTopButton from "../components/ScrollToTopButton.vue";
import TopBarContactDialogButton from "../components/TopBarContactDialogButton.vue";
import TopBarContactDialog from "../components/TopBarContactDialog.vue";
import Section1SmallScreenCard from "../components/Section1SmallScreenCard.vue";
import CookiesSheet from "../components/CookiesSheet.vue";

export default defineComponent({
    name: "HomeView",

    components: {
        Section1Component,
        Section2Component,
        Section3Component,
        BottomBarComponent,
        BottomBarContactDialog,
        ScrollToTopButton,
        TopBarContactDialogButton,
        TopBarContactDialog,
        Section1SmallScreenCard,
        CookiesSheet,
    },
});
</script>
