<template>
    <v-carousel
        height="700px"
        show-arrows="hover"
        class="mb-4"
        cycle
        hide-delimiter-background>
        <v-carousel-item
            v-for="(item, i) in carousel_items"
            :key="i"
            :src="item.src"
            reverse-transition="slide-x-reverse-transition"
            transition="slide-x-transition"
            eager></v-carousel-item>
    </v-carousel>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "Section3Carousel",
    props: ["carousel_items"],
    components: {},
});
</script>
<style scoped>
.-transition-enter-active {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
</style>
