<template>
    <v-hover v-slot="{ isHovering, props }" open-delay="200" close-delay="200">
        <v-col>
            <div
                class="d-flex justify-center text-white menu-link text-center courgette-regular"
                :class="
                    heading
                        ? heading
                        : {
                              'text-h4': $vuetify.display.mdAndUp,
                              'text-subtitle-2': $vuetify.display.smAndDown,
                          }
                "
                nav
                v-bind="props"
                width="100%">
                <router-link
                    v-if="linkTo && scrollTo"
                    :to="{ name: linkTo, hash: scrollTo }">
                    {{ buttonText }}
                </router-link>
                <router-link v-else-if="linkTo" :to="{ name: linkTo }">
                    {{ buttonText }}
                </router-link>
                <div v-else>{{ buttonText }}</div>
            </div>
            <v-flex shrink v-if="hoverUnderline">
                <v-expand-x-transition appear origin="center center">
                    <v-divider
                        :thickness="4"
                        class="mt-2 mb-n3 justify-center"
                        color="white"
                        opacity="100"
                        v-show="isHovering"></v-divider>
                </v-expand-x-transition>
            </v-flex>
        </v-col>
    </v-hover>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "Section1MenuButton",
    props: {
        buttonText: {
            default: "Text",
        },
        heading: {
            default: null,
        },
        hoverUnderline: {
            default: true,
            type: Boolean,
        },
        linkTo: {
            default: null,
        },
        scrollTo: {
            default: null,
        },
    },
    components: {},
});
</script>

<style scoped></style>
