<template>
    <v-container :fluid="true" class="ma-0 pa-0" id="component3">
        <v-row class="mb-12 mt-12">
            <v-col cols="12">
                <div class="text-h5 text-md-h3 playfair-display text-center">
                    {{ $t("landingPage.component3.discover") }}
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-tabs v-model="tab" align-tabs="center" color="green">
                    <v-tab class="text-h6 text-md-h4" :value="1">
                        <div class="playfair-display">
                            {{
                                $t("landingPage.component3.tabSelector.outside")
                            }}
                        </div>
                    </v-tab>
                    <v-tab class="text-h6 text-md-h4" :value="2">
                        <div class="playfair-display">
                            {{
                                $t(
                                    "landingPage.component3.tabSelector.downstairs"
                                )
                            }}
                        </div>
                    </v-tab>
                    <v-tab class="text-h6 text-md-h4" :value="3">
                        <div class="playfair-display">
                            {{
                                $t(
                                    "landingPage.component3.tabSelector.upstairs"
                                )
                            }}
                        </div>
                    </v-tab>
                    <v-tab class="text-h6 text-md-h4" :value="4">
                        <div class="playfair-display">
                            {{
                                $t(
                                    "landingPage.component3.tabSelector.surroundings"
                                )
                            }}
                        </div>
                    </v-tab>
                </v-tabs>
                <v-tabs-window v-model="tab" class="mt-8">
                    <v-tabs-window-item :value="1" :key="1">
                        <section3-carousel
                            :carousel_items="
                                carousel_items_outside
                            "></section3-carousel>
                    </v-tabs-window-item>
                    <v-tabs-window-item :value="2" :key="2">
                        <section3-carousel
                            :carousel_items="
                                carousel_items_downstairs
                            "></section3-carousel>
                    </v-tabs-window-item>
                    <v-tabs-window-item :value="3" :key="3">
                        <section3-carousel
                            :carousel_items="
                                carousel_items_upstairs
                            "></section3-carousel>
                    </v-tabs-window-item>
                    <v-tabs-window-item :value="4" :key="4">
                        <section3-carousel
                            :carousel_items="
                                carousel_items_surroundings
                            "></section3-carousel>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import Section3Carousel from "./Section3Carousel.vue";

// Components
export default defineComponent({
    name: "Section3Component",
    data: () => ({
        tab: 1,
        carousel_items_outside: [
            // { src: require("@/assets/Carousel images/Outside/1.jpg") },
            { src: require("@/assets/Carousel images/Outside/2.jpg") },
            { src: require("@/assets/Carousel images/Outside/3.jpg") },
            { src: require("@/assets/Carousel images/Outside/4.jpg") },
            { src: require("@/assets/Carousel images/Outside/5.jpg") },
            { src: require("@/assets/Carousel images/Outside/6.jpg") },
            { src: require("@/assets/Carousel images/Outside/7.jpg") },
            { src: require("@/assets/Carousel images/Outside/8.jpg") },
            { src: require("@/assets/Carousel images/Outside/9.jpg") },
            { src: require("@/assets/Carousel images/Outside/10.jpg") },
            { src: require("@/assets/Carousel images/Outside/11.jpg") },
            { src: require("@/assets/Carousel images/Outside/12.jpg") },
        ],
        carousel_items_downstairs: [
            { src: require("@/assets/Carousel images/Downstairs/1.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/2.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/3.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/4.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/5.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/6.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/7.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/8.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/9.jpg") },
            { src: require("@/assets/Carousel images/Downstairs/10.jpg") },
        ],
        carousel_items_upstairs: [
            { src: require("@/assets/Carousel images/Upstairs/1.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/2.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/3.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/4.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/5.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/6.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/7.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/8.jpg") },
            { src: require("@/assets/Carousel images/Upstairs/9.jpg") },
        ],
        carousel_items_surroundings: [
            { src: require("@/assets/Carousel images/Surroundings/1.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/2.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/3.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/4.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/5.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/6.jpg") },
            { src: require("@/assets/Carousel images/Surroundings/7.jpg") },
        ],
    }),
    components: { Section3Carousel },
});
</script>
<style scoped>
.-transition-enter-active {
    position: absolute;
    top: 0;
    width: 100%;
}
</style>
