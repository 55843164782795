<template>
    <v-container class="my-8 pa-0" id="component2">
        <v-row class="mb-12 mt-12">
            <v-col cols="12" md="12">
                <div class="text-h5 text-md-h3 playfair-display text-center">
                    {{ $t("landingPage.component2.learnMore") }}
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-tabs v-model="tab" align-tabs="center" color="green">
                    <v-tab class="text-h6 text-md-h4" :value="1">
                        <div class="playfair-display">
                            {{ $t("landingPage.component2.tabSelector.house") }}
                        </div>
                    </v-tab>
                    <v-tab class="text-h6 text-md-h4" :value="2">
                        <div class="playfair-display">
                            {{
                                $t(
                                    "landingPage.component2.tabSelector.location"
                                )
                            }}
                        </div>
                    </v-tab>
                    <v-tab class="text-h6 text-md-h4" :value="3">
                        <div class="playfair-display">
                            {{
                                $t(
                                    "landingPage.component2.tabSelector.accomodation"
                                )
                            }}
                        </div>
                    </v-tab>
                </v-tabs>
                <v-tabs-window v-model="tab" class="mt-8">
                    <v-tabs-window-item :value="1" :key="1">
                        <section-2-tab-the-house></section-2-tab-the-house>
                    </v-tabs-window-item>
                    <v-tabs-window-item :value="2" :key="2">
                        <section-2-tab-location></section-2-tab-location>
                    </v-tabs-window-item>
                    <v-tabs-window-item :value="3" :key="3">
                        <section-2-tab-accomodation></section-2-tab-accomodation>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import Section2TabTheHouse from "./Section2TabTheHouse.vue";
import Section2TabLocation from "./Section2TabLocation.vue";
import Section2TabAccomodation from "./Section2TabAccomodation.vue";

// Components
export default defineComponent({
    name: "Section2Component",
    data: () => ({
        tab: 1,
    }),
    components: {
        Section2TabTheHouse,
        Section2TabAccomodation,
        Section2TabLocation,
    },
});
</script>
<style scoped></style>
