<template>
    <v-sheet
        class="pa-6 text-black mx-auto position-fixed bottom-0 right-0 mr-8 mb-16"
        color="white"
        max-width="400"
        v-show="isVisible">
        <h4 class="text-h5 font-weight-bold mb-4">
            {{ $t("landingPage.cookiesSheet.title") }}
        </h4>

        <p class="mb-8">
            {{ $t("landingPage.cookiesSheet.p1") }}
        </p>
        <p class="mb-8">
            {{ $t("landingPage.cookiesSheet.p21") }}
            <a
                class="text-red-accent-2 cursor-pointer"
                @click="togglePrivacyPolicyDialogOpen()">
                {{ $t("landingPage.cookiesSheet.p2pp") }}
            </a>
            {{ $t("landingPage.cookiesSheet.p22") }}
        </p>

        <v-btn
            class="text-none text-black mb-4"
            color="red-accent-2"
            size="x-large"
            variant="flat"
            block
            @click="enablePlugin()">
            {{ $t("landingPage.cookiesSheet.accept") }}
        </v-btn>

        <v-btn
            class="text-none text-black"
            color="red-accent-2"
            size="x-large"
            variant="outlined"
            block
            @click="isVisible = false">
            {{ $t("landingPage.cookiesSheet.decline") }}
        </v-btn>
    </v-sheet>
</template>
<script>
import { defineComponent } from "vue";
import { bootstrap } from "vue-gtag";
import { mapActions } from "vuex";

// Components
export default defineComponent({
    name: "CookiesSheet",

    components: {},
    data: () => ({
        isVisible: true,
    }),
    methods: {
        ...mapActions("LandingPage", ["togglePrivacyPolicyDialogOpen"]),
        enablePlugin() {
            bootstrap().then((gtag) => {
                this.isVisible = false;
                this.$cookies.set("givenConsent", "true", "1Y");
                console.log(gtag);
            });
        },
    },
    computed: {},
    mounted() {
        var givenConsent = this.$cookies.get("givenConsent");
        if (givenConsent) {
            if (givenConsent == "true") {
                bootstrap().then((gtag) => {
                    this.isVisible = false;
                    console.log(gtag);
                });
            }
        } else {
            this.isVisible = true;
        }
    },
});
</script>
<style scoped></style>
