<template>
    <v-container :fluid="true" class="ma-0 pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <v-parallax
                    src="../assets/background.jpg"
                    height="100%"
                    min-height="550">
                    <locale-selector></locale-selector>
                    <v-container
                        :fluid="true"
                        class="position-absolute right-0 top-0"></v-container>

                    <section-1-menu></section-1-menu>

                    <section-1-card
                        class="position-absolute ml-2 ml-md-16 mt-16 mt-md-0"
                        v-if="$vuetify.display.mdAndUp"
                        :class="{
                            'bottom-50': $vuetify.display.mdAndUp,
                            'bottom-10': $vuetify.display.smAndDown,
                        }"></section-1-card>
                </v-parallax>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import LocaleSelector from "./LocaleSelector.vue";
import Section1Card from "./Section1Card.vue";
import Section1Menu from "./Section1Menu.vue";

// Components
export default defineComponent({
    name: "Section1Component",

    components: {
        Section1Card,
        Section1Menu,
        LocaleSelector,
    },
});
</script>
<style scoped>
.bottom-50 {
    bottom: 50%;
}
.bottom-10 {
    bottom: 10%;
}
</style>
