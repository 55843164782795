<template>
    <v-select
        class="position-absolute left-0 top-0 mt-md-4 ml-1 ml-md-5 selectFont"
        :class="{
            smallFont: $vuetify.display.smAndDown,
        }"
        v-model="$i18n.locale"
        :items="availableLanguages"
        variant="underlined"
        value="PL"></v-select>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "LocaleSelector",

    components: {},
    data: () => ({
        availableLanguages: [
            { title: "EN", value: "en" },
            { title: "PL", value: "pl" },
        ],
    }),
});
</script>
<style scoped lang="scss">
.selectFont {
    color: #faf9f6;
}

.smallFont:deep(.v-field) {
    font-size: 0.7rem !important;
}
</style>
