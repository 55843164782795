<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
.playfair-display {
    font-family: "Playfair Display", serif !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
}
.courgette-regular {
    font-family: "Courgette", cursive !important;
}
a {
    text-decoration: none;
    color: #faf9f6;

    &.router-link-exact-active {
        color: #faf9f6;
    }
    &:hover {
        color: #faf9f6;
    }
}
</style>
