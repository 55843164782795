<template>
    <v-slide-y-reverse-transition>
        <v-btn
            v-scroll="onScroll"
            v-show="isVisible"
            fab
            dark
            icon="mdi-chevron-up"
            color="green"
            class="position-fixed bottom-0 right-0 mr-8 mb-16"
            @click="toTop"></v-btn>
    </v-slide-y-reverse-transition>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "ScrollToTopButton",

    components: {},
    data: () => ({
        isVisible: false,
    }),
    methods: {
        onScroll() {
            this.isVisible = window.scrollY > 50;
        },
        toTop() {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        },
    },
    computed: {},
});
</script>
<style scoped>
.mb-28 {
    margin-bottom: 112px;
}
</style>
