<template>
    <v-container class="pa-2">
        <v-row no-gutters>
            <v-col cols="12" class="justify-center">
                <section-1-card class="mt-8"></section-1-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Section1Card from "./Section1Card.vue";

// Components
export default defineComponent({
    name: "Section1SmallScreenCard",

    components: {
        Section1Card,
    },
});
</script>
<style scoped></style>
