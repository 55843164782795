<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4" class="mr-md-0 mr-sm-4">
                <GMapMap
                    :center="center"
                    :zoom="14"
                    map-type-id="roadmap"
                    style="width: 100%; height: 400px">
                    <GMapMarker
                        :key="marker.id"
                        :position="marker.position"
                        :icon="{
                            url: require('@/assets/HomeIcon.svg'),
                            scaledSize: { width: 30, height: 30 },
                        }" />
                </GMapMap>
            </v-col>
            <v-col
                cols="12"
                md="8"
                class="border-opacity-100 border-green"
                :class="{
                    'border-s-md': $vuetify.display.mdAndUp,
                    'border-t-md': $vuetify.display.smAndDown,
                }">
                <div class="text-body-1 text-md-h5">
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.location.p1") }}
                    </p>
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.location.p2") }}
                    </p>
                    <p class="mb-4 mr-16 playfair-display">
                        {{ $t("landingPage.component2.tabText.location.p3") }}
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";

// Components
export default defineComponent({
    name: "Section2TabLocation",
    data() {
        return {
            center: { lat: 42.6777698, lng: 12.2771295 },
            marker: {
                id: "La Casa Di Dario",
                position: {
                    lat: 42.6777698,
                    lng: 12.2771295,
                },
            },
        };
    },
    components: {},
});
</script>
<style scoped></style>
