<template>
    <v-container fluid="true" class="ma-0 pa-0">
        <v-row height="100px">
            <v-spacer></v-spacer>
            <v-col md="3" sm="3">
                <section-1-menu-button
                    :buttonText="$t('landingPage.component1.menu.learnMore')"
                    linkTo="home"
                    scrollTo="#component2"></section-1-menu-button>
            </v-col>
            <v-col md="3" sm="6">
                <v-img
                    class="mt-4"
                    min-width="150"
                    src="../assets/Logo - didario.png"
                    cover></v-img>
            </v-col>
            <v-col md="3" sm="3">
                <section-1-menu-button
                    :buttonText="$t('landingPage.component1.menu.photos')"
                    linkTo="home"
                    scrollTo="#component3"></section-1-menu-button>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import Section1MenuButton from "./Section1MenuButton.vue";

// Components
export default defineComponent({
    name: "Section1Menu",

    components: { Section1MenuButton },
});
</script>

<style scoped></style>
