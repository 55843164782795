<template>
    <v-container :fluid="true" class="ma-0 pa-0">
        <v-row no-gutters class="bg-grey" height="50" align="stretch">
            <v-col
                cols="12"
                md="3"
                class="d-flex my-2 justify-center justify-md-start">
                <bottom-bar-privacy-policy-dialog></bottom-bar-privacy-policy-dialog>
            </v-col>
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="2" class="d-flex justify-center align-center">
                <div class="text-h7 text-center mt-2">
                    {{ $t("landingPage.bottomBar.copyright") }}
                </div>
            </v-col>
            <v-col cols="0" md="2"></v-col>
            <v-col
                cols="12"
                md="3"
                class="d-flex my-2 justify-center justify-md-end">
                <bottom-bar-contact-dialog-button></bottom-bar-contact-dialog-button>
            </v-col>
        </v-row>
        <v-row no-gutters class="bg-grey" height="50" align="stretch">
            <v-col cols="12" md="12" class="d-flex justify-center align-center">
                <v-btn
                    variant="plain"
                    v-if="$i18n.locale == 'en'"
                    href="Casa di Dario_regulamin ENG.pdf">
                    {{ $t("landingPage.bottomBar.downloadRules") }}
                </v-btn>
                <v-btn
                    variant="plain"
                    v-else-if="$i18n.locale == 'pl'"
                    href="Casa di Dario_regulamin PL.pdf">
                    {{ $t("landingPage.bottomBar.downloadRules") }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import BottomBarContactDialogButton from "./BottomBarContactDialogButton.vue";
import BottomBarPrivacyPolicyDialog from "./BottomBarPrivacyPolicyDialog.vue";

// Components
export default defineComponent({
    name: "BottomBar",

    components: { BottomBarContactDialogButton, BottomBarPrivacyPolicyDialog },
    computed: {},
    methods: {},
});
</script>
<style scoped></style>
