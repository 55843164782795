<template>
    <div class="text-center pa-4 position-fixed bottom-0 mb-8">
        <v-slide-y-reverse-transition>
            <v-btn
                v-scroll="onScroll"
                v-show="isVisible"
                :prepend-icon="
                    !getTopContactDialogOpen
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                "
                class="mr-4 button-green"
                size="large"
                @click="toggleTopContactDialogOpen()">
                {{ $t("landingPage.contactInfoButton.seeContactInfo") }}
            </v-btn>
        </v-slide-y-reverse-transition>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

// Components
export default defineComponent({
    name: "BottomBarContactDialogButton",

    components: {},
    data: () => ({
        isVisible: true,
    }),
    methods: {
        ...mapActions("LandingPage", ["toggleTopContactDialogOpen"]),
        onScroll() {
            if (this.getPrivacyPolicyDialogOpen) {
                this.isVisible = false;
            } else {
                this.isVisible = !(
                    window.innerHeight + Math.round(window.scrollY) >=
                    document.body.offsetHeight
                );
            }
        },
    },
    computed: {
        ...mapGetters("LandingPage", {
            getTopContactDialogOpen: "getTopContactDialogOpen",
            getPrivacyPolicyDialogOpen: "getPrivacyPolicyDialogOpen",
        }),
    },
});
</script>
<style scoped>
.mb-28 {
    margin-bottom: 112px;
}
</style>
