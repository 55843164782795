<template>
    <div class="text-center pa-4">
        <v-btn
            :prepend-icon="
                !getContactDialogOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'
            "
            variant="tonal"
            class="mr-4"
            @click="toggleContactDialogOpen()">
            {{ $t("landingPage.bottomBar.seeContactInfo") }}
        </v-btn>
    </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

// Components
export default defineComponent({
    name: "BottomBarContactDialogButton",

    components: {},
    methods: {
        ...mapActions("LandingPage", ["toggleContactDialogOpen"]),
    },
    computed: {
        ...mapGetters("LandingPage", {
            getContactDialogOpen: "getContactDialogOpen",
        }),
    },
});
</script>
<style scoped>
.v-dialog {
    bottom: 0vh;
    right: 0vw;
}
</style>
